'use strict';

// const axios = require('axios');

axios.defaults.baseURL = 'https://fiware.connector.argonet.it/api';

const applicationServerPublicKey = 'BBEHNmAfb-WDTk8SerH1EkI1pg7y-2QmkkYw73Vmk77oHWXU1LC3TTXHKF3q6ofMiXpZTdo0kuwJNFHuv6o3aPQ';

const pushButton = document.querySelector('.js-push-btn');
const pushState = document.querySelector('.js-push-state');

let isSubscribed = false;
let swRegistration = null;

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateBtn() {
  if (Notification.permission === 'denied') {
    pushButton.textContent = 'Permesso negato alla ricezione di notifiche';
    pushButton.disabled = true;
    updateSubscriptionOnServer(null);
    return;
  }

  if (isSubscribed) {
    pushButton.textContent = 'Disabilita ricezione notifiche';
    pushState.innerHTML = 'Abilitata';
  } else {
    pushButton.textContent = 'Abilita ricezione notifiche';
    pushState.innerHTML = 'Disabilitata';
  }

  pushButton.disabled = false;
}

function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server

  // const subscriptionJson = document.querySelector('.js-subscription-json');
  // const subscriptionDetails =
  //   document.querySelector('.js-subscription-details');

  // if (subscription) {
  //   subscriptionJson.textContent = JSON.stringify(subscription);
  //   subscriptionDetails.classList.remove('visually-hidden');
  // } else {
  //   subscriptionDetails.classList.add('visually-hidden');
  // }
}

function subscribeUser() {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey
  })
  .then(function(subscription) {
    console.log('User is subscribed.');

    // updateSubscriptionOnServer(subscription);
    const axios_config = {
      crossDomain: true,
      timeout: 2000,
      headers: {
        Authorization: "Basic YW5nZXJhOmFuZ2VyYQ=="
      }
    };

    axios.post('/v1/register', JSON.stringify(subscription), axios_config)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    isSubscribed = true;

    updateBtn();
  })
  .catch(function(err) {
    console.log('Failed to subscribe the user: ', err);
    updateBtn();
  });
}

function unsubscribeUser() {
  swRegistration.pushManager.getSubscription()
  .then(function(subscription) {
    if (subscription) {
      return subscription.unsubscribe().then(function() {
        
        // updateSubscriptionOnServer(null);
        // const axios_config = {
        //   crossDomain: true,
        //   timeout: 2000,
        //   headers: {
        //     Authorization: "Basic YW5nZXJhOmFuZ2VyYQ=="
        //   }
        // };
    
        // axios.post('/v1/unregister', JSON.stringify(subscription), axios_config)
        // .then(function (response) {
        //   console.log(response);
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
    
        console.log('User is unsubscribed.');
        isSubscribed = false;
    
        updateBtn();
      });
    }
  })
  .catch(function(error) {
    console.log('Error unsubscribing', error);
  });
}

function initializeUI() {
  pushButton.addEventListener('click', function() {
    pushButton.disabled = true;
    if (isSubscribed) {
      unsubscribeUser();
    } else {
      subscribeUser();
    }
  });

  // Set the initial subscription value
  swRegistration.pushManager.getSubscription()
  .then(function(subscription) {
    console.log(subscription);
    isSubscribed = !(subscription === null);

    // updateSubscriptionOnServer(subscription);


    if (isSubscribed) {
      console.log('User IS subscribed.');
    } else {
      console.log('User is NOT subscribed.');
    }

    updateBtn();
  });
}

function launchToast() {
  var toast = document.getElementById("toast");
  // var desc = document.getElementById("desc");
  // desc.innerHTML = '...';
  toast.className = "show";
  // desc.innerHTML = message;
  // setTimeout(function(){ 
  //   toast.className = toast.className.replace("show", ""); 
  // }, 5000);
}

function showRefreshUI(registration) {
  launchToast();
  var button = document.getElementById("refreshApp");
  button.addEventListener("click",function(e) {
    e.preventDefault();
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }

    // button.disabled = true;
    var toast = document.getElementById("toast");
    toast.className = toast.className.replace("show", "");

    registration.waiting.postMessage('skipWaiting');
  },false);
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

function getUrlParam(parameter, defaultvalue){
  var urlparameter = defaultvalue;
  if(window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

function showEvent() {
  var title = decodeURIComponent(getUrlParam('t', false));
  var date = decodeURIComponent(getUrlParam('d', false));
  var link = decodeURIComponent(getUrlParam('l', false));
  var body = decodeURIComponent(getUrlParam('b', false));
  var image = decodeURIComponent(getUrlParam('i', false));

  if (date) {
    date = dayjs(date).format('DD/MM/YYYY');
  }

  var e_home = document.getElementById("page-home");
  var e_event = document.getElementById("page-event");
  const e_title = document.querySelector('.event-title');
  const e_date = document.querySelector('.event-date');
  const e_foto = document.querySelector('.event-foto img');
  const e_testo = document.querySelector('.event-testo');
  const e_link = document.querySelector('.event-link .event-link__link');

  e_home.className = "hide";
  e_event.className = e_event.className.replace("hide", "");

  e_title.innerHTML = title;
  e_date.innerHTML = date;
  e_testo.innerHTML = body;
  if (image != 'undefined') {
    e_foto.setAttribute('src', image); 
  } else {
    e_foto.parentNode.className = e_foto.parentNode.className + " hide";
  }
  e_link.setAttribute('href', link);
}

document.addEventListener('DOMContentLoaded', function() {
  var title = getUrlParam('t', false);

  if(!title) {
    var e_home = document.getElementById("page-home");
    e_home.className = e_home.className.replace("hide", "");
    return;
  }

  showEvent();
}, false);

function onNewServiceWorker(registration, callback) {
  console.log(registration);
  if (registration.waiting) {
    // SW is waiting to activate. Can occur if multiple clients open and
    // one of the clients is refreshed.
    return callback();
  }

  function listenInstalledStateChange() {
    registration.installing.addEventListener('statechange', function(event) {
      if (event.target.state === 'installed') {
        // A new service worker is available, inform the user
        callback();
      }
    });
  };

  if (registration.installing) {
    return listenInstalledStateChange();
  }

  // We are currently controlled so a new SW may be found...
  // Add a listener in case a new SW is found,
  registration.addEventListener('updatefound', listenInstalledStateChange);
}

window.addEventListener('load', function() {
  // launchToast();
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    var refreshing;
    // When the user asks to refresh the UI, we'll need to reload the window
    navigator.serviceWorker.addEventListener('controllerchange', function(event) {
      if (refreshing) return; // prevent infinite refresh loop when you use "Update on Reload"
      refreshing = true;
      console.log('Controller loaded');
      window.location.reload();
    });

    navigator.serviceWorker.register('sw.js')
    .then(function (registration) {
      swRegistration = registration;
      initializeUI();
      // Track updates to the Service Worker.
      if (!navigator.serviceWorker.controller) {
        // The window client isn't currently controlled so it's a new service
        // worker that will activate immediately
        return;
      }
      registration.update();

      onNewServiceWorker(registration, function() {
        // showRefreshUI(registration);
        console.log('refresh ui');
        showRefreshUI(registration);
      });
    })
    .catch(function(error) {
      console.error('Service Worker Error', error);
    });
  } else {
    console.warn('Push messaging is not supported');
    pushButton.textContent = 'Push Not Supported';
  }
});

// if ('serviceWorker' in navigator && 'PushManager' in window) {
//   console.log('Service Worker and Push is supported');

//   navigator.serviceWorker.register('sw.js')
//   .then(function(swReg) {
//     console.log('Service Worker is registered', swReg);

//     swRegistration = swReg;
//     initializeUI();
//   })
//   .catch(function(error) {
//     console.error('Service Worker Error', error);
//   });
// } else {
//   console.warn('Push messaging is not supported');
//   pushButton.textContent = 'Push Not Supported';
// }
